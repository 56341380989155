import React, { useState } from 'react'
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { api } from '../api/api';
import ModalEditBadges from '../components/ModalEditBadge/ModalEditBadge';
import { useAccessToken, useAuthReducer, useNotificationReducer, useOneToOneReducer } from '../hooks/ReducerHooks/ReducerHooks';
import useReducerAuthAction from '../hooks/ReducerHooksAction/useReducerAuthAction';
import Agora from '../modules/Agora/Agora';
import AgoraDetails from '../modules/Agora/AgoraDetails/AgoraDetails';
import AgoraMembers from '../modules/Agora/AgoraMembers/AgoraMembers';
import AppLayout from '../modules/AppLayout/AppLayout';
import Home from '../modules/Home/Home'
import OneToOne from 'modules/OneToOne/OneToOneConversation/OneToOne';
import OneToOneMessage from '../modules/OneToOne/OneToOneMessage/OneToOneMessage';
import Profile from '../modules/Profile/Profile';
import ChangePassword from '../modules/Profile/ChangePassword/ChangePassword';
import Public from '../modules/Public/Public';
import Transactions from '../modules/Profile/Transactions/Transactions';
import OpinionDetails from '../modules/OpinionDetails/OpinionDetails';
import PrivacyPolicy from '../modules/Legal/en/PrivacyPolicy';
import TermsConditions from '../modules/Legal/en/TermsConditions';
import PolitiqueConfidentialite from '../modules/Legal/fr/PolitiqueConfidentialite';
import ConditionsGenerales from '../modules/Legal/fr/ConditionsGenerales';
import useReducerNotifAction from '../hooks/ReducerHooksAction/useReducerNotifAction';
import useReducerPeopleMatchAction from '../hooks/ReducerHooksAction/useReducerPeopleMatchAction';
import useReducerOneToOneAction from '../hooks/ReducerHooksAction/useReducerOneToOneAction';
import PeopleYouMatch from '../modules/PeopleYouMatch/PeopleYouMatch';
import Notifications from '../modules/Notifications/Notifications';
import Intranet from '../modules/Intranet/Intranet';
import Requests from '../modules/Intranet/Resquests/Requests';
import NewRequest from '../modules/Intranet/Resquests/NewRequest/NewRequest';
import RequestSent from '../modules/Intranet/Resquests/RequestSent/RequestSent';
import RequestReceived from '../modules/Intranet/Resquests/RequestReceived/RequestReceived';
import RequestDetails from '../modules/Intranet/Resquests/RequestDetails/RequestDetails';
import HolidayBalance from '../modules/Intranet/HolidayBalance/HolidayBalance';
import EventList from 'modules/Intranet/Events/EventList/EventList';
import EditProfile from '../modules/Profile/EditProfile/EditProfile';
import EventDetails from 'modules/Intranet/Events/EventDetails/EventDetails';
import Settings from 'modules/Profile/Settings/screens/Settings';
import AddAccount from 'modules/Profile/Settings/screens/AddAccount/AddAccount';
import CreatePage from 'modules/Profile/Settings/screens/CreatePage/CreatePage';
import PageSetting from 'modules/Profile/Settings/screens/PageSetting/PageSetting';
import ModerationRequests from 'modules/Profile/Settings/screens/ModerationRequests/ModerationRequests';
import SendModerationRequest from 'modules/Profile/Settings/screens/SendModerationRequest/SendModerationRequest';
import Audience from 'modules/Profile/Audience/Audience';
import MyCalendar from 'modules/Intranet/Calendar/Calendar';
import Search from 'modules/Search/Search';
import SearchBadge from 'modules/SearchBadge/SearchBadge';
import SearchRH from 'modules/SearchHR/SearchRH';
import SearchCategory from 'modules/SearchCategory/SearchCategory';
import AIAssistant from 'modules/Intranet/AIAssistant/AIAssistant';
import Completions from 'modules/Intranet/AIAssistant/Completions/Completions';
import ImageGeneration from 'modules/Intranet/AIAssistant/ImageGeneration/ImageGeneration';
import SpeechToText from 'modules/Intranet/AIAssistant/SpeechToText/SpeechToText';
import SpeechToTranslate from 'modules/Intranet/AIAssistant/SpeechToTranslate/SpeechToTranslate';
import Organizational from 'modules/Profile/Organizational/Organizational';


function AppStack() {

    // STATE
    const accessToken = useAccessToken()
    const { data: authData } = useAuthReducer()
    const { data: notifData } = useNotificationReducer()
    const { UPDATE_PROFILE, UPDATE_AUDIENCE, LOGOUT } = useReducerAuthAction()
    const { UPDATE_NOTIFICATIONS_NUMBER, REFRESH_NOTIFICATIONS, ADD_NOTIFICATIONS } = useReducerNotifAction()
    const { REFRESH_MESSAGE_NUMBER, REFRESH_CONVERSATION, REFRESH_BUDDIES } = useReducerOneToOneAction()
    const { REFRESH_PEOPLE } = useReducerPeopleMatchAction()
    const [showEditBadge, setShowEditBadge] = useState(false)

    const controller = new AbortController();
    const signal = controller.signal

    // HANDLE
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const request = await api('api/get-user', 'GET', {}, { ...accessToken, ...signal })
                const response = await request.json()
                // console.log('Response fetch user:', response?.user)
                if (request.ok && request.status === 200) {
                    UPDATE_PROFILE(response?.user)
                    if (!response?.user?.user_badge_goal) {
                        setShowEditBadge(true)
                    }
                } else if (request.status === 401 || request.status === 403) {
                    LOGOUT()
                }
            } catch (e) {
                fetchUser()
                console.warn('Error fetch user info:', e.message)
                throw new Error(e)
            }
        }
        fetchUser()
        return () => controller.abort()
    }, []);



    //  FETCH AUDIENCE
    useEffect(() => {
        const fetchAudience = async () => {
            try {
                const request = await api(`api/user/audience/${authData?.user?.user_id}`, 'GET', null, { ...accessToken, ...signal })
                const response = await request.json()
                // console.log('Response fetch audience updated:', response)
                if (request.ok && request.status === 200) {
                    if (response.success) {
                        if (response?.audience[0]) {
                            UPDATE_AUDIENCE(response?.audience[0])
                        }
                    }
                }
            } catch ({ message }) {
                throw new Error(message)
            }
        }
        fetchAudience()
        return () => controller.abort()
    }, [])



    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                if (notifData?.notifications?.lenght !== 0) {
                    return
                }
                const request = await api('api/v2/notifications', 'GET', null, { ...accessToken, ...signal })
                const response = await request.json()
                // console.log('Response fetch notifications:', response)
                if (request.status == 200 && response.success) {
                    if (response?.notifications.length !== 0) {
                        REFRESH_NOTIFICATIONS(response?.notifications)
                    }
                }
            } catch (e) {
                console.warn('Error fetch all notifications:', e)
            }
        }
        fetchNotifications()
        return () => controller.abort()
    }, [])



    useEffect(() => {
        const fetchNewNotifications = async () => {
            try {
                const request = await api('api/v2/notifications', 'GET', null, { ...accessToken, ...signal })
                const response = await request.json()
                // console.log('Response fetch notifications:', response)
                if (request.status == 200 && response.success) {
                    if (response?.notifications.length !== 0) {
                        ADD_NOTIFICATIONS(response?.notifications)
                        UPDATE_NOTIFICATIONS_NUMBER(response?.notifications?.length)
                    }
                }
            } catch (e) {
                console.warn('Error fetch all notifications:', e)
            }
            // try {
            //     const request = await api(`api/notifications/${0}`, 'GET', null, { ...accessToken, ...signal })
            //     const response = await request.json()
            //     // console.log('Response fetch new notification:', response?.notifications?.length + ' new notifications')
            //     if (request.status === 200 && response?.success) {
            //         if (response?.notifications?.length !== 0) {
            //             ADD_NOTIFICATIONS(response?.notifications)
            //             UPDATE_NOTIFICATIONS_NUMBER(response?.notifications?.length)

            //             /* UPDATE NOTIFICATION STATUS */
            //             // updateNotificationStatus()
            //         }
            //     }
            // } catch (error) {
            //     console.log('Error fetch new notifications:', error)
            // }
        }

        fetchNewNotifications()
        const notifTimer = setInterval(() => {
            fetchNewNotifications()
        }, 20000);
        return () => {
            clearInterval(notifTimer)
            return () => controller.abort()
        }
    }, [])


    const updateNotificationStatus = async () => {
        try {
            const data = { state: 0 }
            const request = await api(`api/confirm-notifications`, 'POST', data, accessToken)
            const response = await request.json()
            // console.log('Reponse update notification:', response?.notification + ' notification status updated')
        } catch (error) {
            console.error('Error update notification status:', error)
        }
    }


    /* GESTION DES POTES && BUDDY YOU MAY KNOW */
    useEffect(() => {
        const fetchBuddiesYouKnow = async () => {
            try {
                const request = await api('api/user/buddies-you-may-want-to-know', 'GET', null, accessToken)
                const response = await request.json()
                // console.log('Response fetch buddy you may know:', response?.result.length)
                if (request.ok && request.status === 200) {
                    if (response.success) {
                        REFRESH_PEOPLE(response?.result)
                    }
                }
            } catch (error) {
                fetchBuddiesYouKnow()
                console.log('Error get buddies-to-know:', error)
                throw new Error(error.message)
            }
        }
        fetchBuddiesYouKnow()
    }, [])





    /* FETCH AND SAVE BUDDIES */
    useEffect(() => {
        const fetchBuddies = async () => {
            try {
                const request = await api('api/user/get-buddies', 'GET', null, accessToken)
                const response = await request.json()
                // console.log('response buddies:', response)
                if (request.ok && request.status === 200) {

                    const buddiesToStore = response.buddies.map(buddy => ({
                        ...buddy,
                        profile: {
                            prof_picture: buddy?.prof_picture,
                        }
                    }))

                    REFRESH_BUDDIES(buddiesToStore)
                }
            } catch (error) {
                console.log('Error get-buddies:', error)
                throw new Error(error.message)
            }
        }
        fetchBuddies()
    }, [])


    useEffect(() => {
        fetchOneToOneConversation(true)
        const interval = setInterval(() => {
            fetchOneToOneConversation()
        }, 15000);
        return () => clearInterval(interval);
    }, []);

    const fetchOneToOneConversation = async (isRefresh = false) => {
        try {
            const request = await api('api/my-messages?paginate&page=${1}', 'GET', null, accessToken)
            const response = await request.json()
            // console.log('Response one to one conversation:', response)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    const numberOfMessage = response?.list?.data?.map(messageData => messageData?.sent_messages?.length)?.reduce(
                        (previousValue, currentValue) => previousValue + currentValue, 0,
                    )
                    REFRESH_MESSAGE_NUMBER(numberOfMessage)

                    if(isRefresh){
                        REFRESH_CONVERSATION(response?.list?.data)
                    }
                }
            }
        } catch (error) {
            console.log('Error get messages:', error)
            throw new Error(error.message)
        }
    }



    return (
        <>
            <Routes>

                <Route path="*" element={<AppLayout />}>

                    <Route index={true} element={<Home />} />
                    <Route path='*' element={<Home />} />
                    <Route path='home' element={<Home />} />
                    <Route path='public' element={<Public />} />
                    <Route path='one-to-one' element={<OneToOne />} />
                    <Route path="one-to-one/:user_id" element={<OneToOneMessage />} />
                    <Route path='agora' element={<Agora />} />
                    <Route path='agora/:ago_id' element={<AgoraDetails />} />
                    <Route path='agora/:ago_id/members' element={<AgoraMembers />} />
                    <Route path="profiles/user/:user_username" element={<Profile />} />
                    <Route path="profiles/user/:user_id/change-password" element={<ChangePassword />} />
                    <Route path="profiles/user/:user_id/network" element={<Audience />} />
                    <Route path="profiles/user/:user_id/settings" element={<Settings />} />
                    <Route path="profiles/user/:user_id/edit" element={<EditProfile />} />
                    <Route path="profiles/user/:user_id/transactions" element={<Transactions />} />
                    <Route path="profiles/user/accounts/add" element={<AddAccount />} />
                    <Route path="profiles/user/pages/create" element={<CreatePage />} />
                    <Route path="profiles/user/pages/:user_id/settings" element={<PageSetting />} />
                    <Route path="profiles/user/pages/:user_id/settings/moderation-requests" element={<ModerationRequests />} />
                    <Route path="profiles/user/pages/:user_id/settings/moderation-requests/send" element={<SendModerationRequest />} />
                    <Route path="profiles/user/organizational/:user_id" element={<Organizational />} />


                    <Route path="opinion/select/:ago_id/:sond_id" element={<OpinionDetails />} />
                    <Route path="search" element={<Search />} />
                    <Route path="search-badge" element={<SearchBadge />} />
                    <Route path="search-rh" element={<SearchRH />} />
                    <Route path="search-category" element={<SearchCategory />} />
                    <Route path="people-you-match" element={<PeopleYouMatch />} />
                    <Route path="notification" element={<Notifications/>} />

                    <Route path="intranet" element={<Intranet />} />

                    <Route path="intranet/request" element={<Requests />} />
                    <Route path="intranet/request/new" element={<NewRequest />} />
                    <Route path="intranet/request/sent" element={<RequestSent />} />
                    <Route path="intranet/request/received" element={<RequestReceived />} />
                    <Route path="intranet/request/:id/details" element={<RequestDetails />} />

                    <Route path="intranet/holiday-balance" element={<HolidayBalance />} />
                    <Route path="intranet/events" element={<EventList />} />
                    <Route path="intranet/events/:event_id/details" element={<EventDetails/>} />
                    <Route path="intranet/calendar" element={<MyCalendar />} />

                    <Route path="intranet/ai-assistant" element={<AIAssistant />} />
                    <Route path="intranet/ai-assistant/completions" element={<Completions />} />
                    <Route path="intranet/ai-assistant/image-generation" element={<ImageGeneration />} />
                    <Route path="intranet/ai-assistant/speech-to-text" element={<SpeechToText />} />
                    <Route path="intranet/ai-assistant/speech-to-translate" element={<SpeechToTranslate />} />

                </Route>

                <Route path='/legal/en/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/legal/en/terms-and-conditions' element={<TermsConditions />} />
                <Route path='/legal/fr/politique-de-confidentialite' element={<PolitiqueConfidentialite />} />
                <Route path='/legal/fr/conditions-generales/' element={<ConditionsGenerales />} />

            </Routes>

            <ModalEditBadges
                user={authData?.user}
                show={showEditBadge}
                setShow={setShowEditBadge}
                refresh={() => setShowEditBadge(false)}
            />

        </>

    )
}

export default AppStack