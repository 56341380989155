import moment from 'moment'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { AiOutlineEyeInvisible } from 'react-icons/ai'
import { FiDownload, FiEdit, FiFlag, FiMoreHorizontal } from 'react-icons/fi'
import { IoWalletOutline } from 'react-icons/io5'
import { MdOutlineDelete, MdOutlinePublic } from 'react-icons/md'
import { RiUserUnfollowLine } from 'react-icons/ri'
import { api } from '../../../api/api'
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks'
import { color } from '../../../hooks/Utils/color'
import Header from '../../Header/Header'
import ModalCreateOpinion from '../../ModalCreateOpinion/ModalCreateOpinion'
import ModalReport from '../../ModalReport/ModalReport'
import ProfileCard from '../../ProfileCard/ProfileCard'


const OpinionHeader = ({
    user,
    opinion,
    userIsMuted,
    user_sond_muted,
    sond_id,
    sond_user,
    sond_caption,
    sond_picture,
    attachments,
    event = {},
    updateOpinion = () => null,
    deleteOpinion = () => null,
    handleMuteUser = () => null,
    sond_finished
}) => {

    const accessToken = useAccessToken()
    const { t } = useTranslation()
    const { data: authData } = useAuthReducer()
    const [dateEnd, setDateEnd] = useState(moment(sond_finished).format("x"))
    const [opinionAttachments, setOpinionAttachments] = useState([])
    const [timer, setTimer] = useState(0)
    const [showMenu, setShowMenu] = useState(false)
    const [showEditOpinion, setShowEditOpinion] = useState(false)
    const [showReportOpinion, setShowReportOpinion] = useState(false)

    const openEditOpinion = () => {
        setShowMenu(false)
        setShowEditOpinion(true)
    }


    useEffect(() => {
        if (opinion?.event) {
            const attachListFilted = opinion?.event?.attachments?.filter((attach) => attach?.attach_url)
            setOpinionAttachments(attachListFilted)
        } else {
            const attachList = [{
                attach_url: opinion?.sond_picture,
                attach_thumbnail: opinion?.sond_thumbnail,
                attach_type: opinion?.sond_type,
            }, ...opinion?.attachments]

            const attachListFilted = attachList?.filter((attach) => attach?.attach_url)

            setOpinionAttachments(attachListFilted)
        }
    }, [opinion])


    useEffect(() => {

        const timerInterval = setInterval(() => {

            const nowtimestamp = new Date().getTime()

            const now = moment();
            const end = moment(sond_finished);
            const duration = moment.duration(end.diff(now));

            const hours = duration.asHours().toFixed(0) - 1;
            const minutes = duration.minutes().toFixed(0);
            const secondes = duration.seconds().toFixed(0);

            if (dateEnd > nowtimestamp) {
                setTimer(`${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${secondes < 10 ? `0${secondes}` : secondes}`)
            } else {
                setTimer(`${moment(sond_finished).format('DD-MM-YYYY')}`)
            }

        }, 1000);

        return () => clearInterval(timerInterval)
    }, [sond_finished])


    const handleDonwloadMedia = (url) => {

        setShowMenu(false)

        const lastSlashPosition = url.lastIndexOf('/')
        const lastPointPosition = url.lastIndexOf('.')
        const fileName = url.slice(lastSlashPosition + 1, lastPointPosition)
        const fileExtension = url.slice(lastPointPosition)

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Access-Control-Allow-Origin', '*');

        fetch(url, { headers }).then((response) => {
            // Convert the response to a blob
            return response.blob();
        })
            .then((blob) => {
                // Create a download link and click it
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(fileName, `${fileName}.${fileExtension}`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });

    }

    const handleDeleteOpinion = async () => {
        try {
            setShowMenu(false)
            const request = await api(
                `api/opinions/${sond_id}`,
                'DELETE',
                null,
                accessToken,
            )
            const response = await request.json()
            console.log(response, 'delete opinion')
            if (request.ok && request.status === 200) {
                if (response.success) {
                    deleteOpinion(response?.opinion)
                }
            }
            setShowMenu(false)
        } catch ({ message }) {
            setShowMenu(false)
            throw new Error(message)
        }
    }



    const muteUserAsync = async () => {
        try {
            const request = await api(`api/profile/mute`, 'POST', { user_id: sond_user }, accessToken)
            const response = await request.json()
            console.log(response, 'user muted')
            if (request.ok && request.status === 200) {
                if (response.success) {
                    handleMuteUser(sond_user)
                }
            }
            setShowMenu(false)
        } catch (e) {
            setShowMenu(false)
            throw new Error(e.message);
        }
    }


    const unmuteUserAsync = async () => {
        try {
            const request = await api(`api/profile/unmute`, 'POST', { user_id: sond_user }, accessToken)
            const response = await request.json()
            console.log(response, 'user unmuted')
            if (request.ok && request.status === 200) {
                if (response.success) {
                    window.location.reload();
                }
            }
            setShowMenu(false)
        } catch (error) {
            setShowMenu(false)
            console.error(error)
        }
    }


    const muteOpinion = async () => {
        try {
            console.log(`Muet opinion ${sond_id}...`)
            const request = await api(`api/opinions/${sond_id}/mute`, 'POST', null, accessToken)
            const response = await request.json()
            console.log('Response mute opinion:', response)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    deleteOpinion(response?.opinion)
                }
            }
            setShowMenu(false)
        } catch (e) {
            setShowMenu(false)
            throw new Error(e.message);
        }
    }


    const unMuteOpinion = async () => {
        try {
            console.log(`Unmuet opinion ${sond_id}...`)
            const request = await api(`api/opinions/${sond_id}/unMute`, 'POST', null, accessToken)
            const response = await request.json()
            console.log('Response unmute opinion:', response)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    window.location.reload();
                }
            }
            setShowMenu(false)
        } catch (e) {
            throw new Error(e.message);
            setShowMenu(false)
        }
    }




    return (
        <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', paddingRight: '20px', marginTop: '10px' }}>

            <ProfileCard
                user={user}
                pictureSize={55}
            />

            <div style={{ height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '15px', marginRight: '15px' }}>

                <button onClick={() => setShowMenu(true)} style={{ width: '20px', marginRight: '15px' }}>
                    <FiMoreHorizontal size={20} color='#808080' />
                </button>

                <div style={{ width: '20px' }}>
                    <MdOutlinePublic size={16} color='#808080' />
                </div>
                {(opinion?.options?.length !== 0 && timer) &&
                    <h6 style={{ width: '50px', color: '#808080', fontSize: '14px', marginLeft: '5px', marginRight: '10px', marginTop: '10px' }}>
                        {timer}
                    </h6>
                }
                <img src={require('assets/app/icon.png')} alt='' width={20} style={{ marginLeft: '5px' }} />
            </div>


            <Modal show={showMenu}>
                <Header title='Menu' type='modal' goBack={() => setShowMenu(false)} />
                <div style={{ padding: '15px' }}>

                    {sond_picture &&
                        <div onClick={() => handleDonwloadMedia(sond_picture)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                            <FiDownload size={24} color='#808080' />
                            <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                                {attachments?.length === 0 ? `${t('download')} media` : `${t('download')} media 1`}
                            </span>
                        </div>
                    }

                    {attachments && attachments?.length !== 0 ?
                        attachments.map((attachment, index) => (
                            <div onClick={() => setShowMenu(false)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                <FiDownload size={24} color='#808080' />
                                <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                                    {t('download')} media {index + 2}
                                </span>
                            </div>
                        ))
                        : null
                    }

                    {authData?.user?.user_id == user?.user_id &&
                        <>
                            <div onClick={openEditOpinion} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                <FiEdit size={24} color='#808080' />
                                <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                                    {t('edit')} opinion
                                </span>
                            </div>

                            <div onClick={handleDeleteOpinion} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                <MdOutlineDelete size={24} color='#808080' />
                                <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                                    {t('delete')}
                                </span>
                            </div>
                        </>
                    }
                    {/* 
                    {authData?.user?.user_id !== user?.user_id &&
                        <>
                            <div onClick={userIsMuted ? unmuteUserAsync : muteUserAsync} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                <RiUserUnfollowLine size={24} color='#808080' />
                                <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                                    {userIsMuted ? t('unmute') : t('mute')} {user?.user_surname} {user?.user_name}
                                </span>
                            </div>

                            <div onClick={user_sond_muted ? unMuteOpinion : muteOpinion} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                <AiOutlineEyeInvisible size={24} color='#808080' />
                                <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                                    {user_sond_muted ? t('unmute') : t('mute')} opinion
                                </span>
                            </div>

                            <div onClick={() => {
                                setShowMenu(false)
                                setShowReportOpinion(true)
                            }}
                                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                <FiFlag size={24} color='#808080' />
                                <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                                    {t('report')}
                                </span>
                            </div>
                        </>
                    } */}

                </div>


            </Modal>



            <ModalCreateOpinion
                user={user}
                sond_id={sond_id}
                caption={sond_caption}
                picture={sond_picture}
                opinion={opinion}
                opinion_attachments={opinionAttachments}

                // EVENT INIT STATE
                event_id={event?.event_id}
                event_title={event?.event_title}
                event_descr={event?.event_descr}
                event_localisation={event?.event_localisation}
                event_date={event?.event_date}
                event_time={event?.event_time}
                event_publish_type={event?.event_publish_type}
                event_attachments={opinion?.event?.attachments}

                show={showEditOpinion}
                setShow={setShowEditOpinion}
            />

            <ModalReport
                user_id={sond_user}
                sond_id={sond_id}
                show={showReportOpinion}
                setShow={setShowReportOpinion}
            />

        </div>
    )
}

export default OpinionHeader