import { color } from "hooks/Utils/color";
import React from "react";
import { Link } from "react-router-dom";


const textFormatter = (text) => {
    if (text) {
        // Define a regular expression to match the pattern @[name](number)
        const regex = /@\[([^\]]+)\]\((\d+)\)/g;

        // Replace the matched pattern with a React component
        const formattedText = text.replace(regex, (match, name, number) => {
           return `@${name}`
        });
        return <span>{formattedText}</span>;
    }
};

function TextWithMentionsAndHashtags({ text }) {
    if (!text) {
        return
    }
    const parts = text?.split(/@\[([\w\s]+)\]\((\d+)\)|#(\w+)/g);
    return (
        <>
            {parts.map((part, index) => (
                <React.Fragment key={index}>
                    {textFormatter(part)}
                    {text.match(/\[([\w\s]+)\]\(([^)]+)\)/g)?.[index] && (
                        <Link
                            text={text.match(/\[([\w\s]+)\]\(([^)]+)\)/g)[index].match(/\[([\w\s]+)\]/)[1]}
                            url={text.match(/\[([\w\s]+)\]\(([^)]+)\)/g)[index].match(/\(([^)]+)\)/)[1]}
                            style={{
                                color: color.primary,
                                textDecoration: 'none',
                                fontWeight: '600'
                            }}
                        />
                    )}
                    {text.match(/@\[([\w\s]+)\]\((\d+)\)/g)?.[index] && (
                        <Mention
                            username={text.match(/@\[([\w\s]+)\]\((\d+)\)/g)[index].match(/@\[([\w\s]+)\]/)[1]}
                            user_id={text.match(/@\[([\w\s]+)\]\((\d+)\)/g)[index].match(/\((\d+)\)/)[1]}
                        />
                    )}
                    {text.match(/#(\w+)/g)?.[index] && (
                        <Hashtag
                            tag={text.match(/#(\w+)/g)[index].replace("#", "")}
                        />
                    )}
                </React.Fragment>
            ))}
        </>
    );
}

const Mention = ({ username, user_id }) => {
    return (
        <Link
            to={`/profile/${user_id}`}
            style={{
                color: color.primary,
                textDecoration: 'none',
                fontWeight: '600'
            }}>
            @{username}{' '}
        </Link>
    );
}

const Hashtag = ({ tag }) => {
    return (
        <Link to={`/search/${tag}`}
            style={{
                color: color.primary,
                textDecoration: 'none',
                fontWeight: '600'
            }}>
            #{tag}{' '}
        </Link>
    );
}

const replaceSpecialCharacters = (text) => {

    const characterMap = {
        'á': 'a',
        'é': 'e',
        'í': 'i',
        'ó': 'o',
        'ú': 'u',
        'é': 'e',
        'è': 'e',
        'ê': 'e',
        'ê': 'e',
        'ë': 'e',
        'ę': 'e',
        'ė': 'e',
        'ē': 'e',
        'â': 'a',
        'à': 'a',
        'æ': 'a',
        'á': 'a',
        'ä': 'a',
        'ã': 'a',
        'å': 'a',
        'ā': 'a',
        "'": '',
    };

    let result = text;
    for (const [specialChar, goodChar] of Object.entries(characterMap)) {
        const regex = new RegExp(specialChar, 'g');
        result = result.replace(regex, goodChar);
    }

    return result
};



export { TextWithMentionsAndHashtags, replaceSpecialCharacters }