import React, { useEffect, useState } from 'react'
import styles from './Organizational.module.css'
import Header from 'components/Header/Header'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks';
import { api } from 'api/api';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom/dist';
import { color } from 'hooks/Utils/color';


const Organizational = () => {


    const { data: authData } = useAuthReducer()
    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const location = useLocation()
    const state = location.state
    const navigate = useNavigate()


    const [user, setUser] = useState(state?.user)
    const [mates, setMates] = useState([])
    const [hierarchicalSubordinates, setHierarchicalSubordinates] = useState([])
    const [upperHierarchy, setUpperHierarchy] = useState(null)

    const [isLoading, setIsLoading] = useState(true)

    const goBack = () => navigate(-1)

    useEffect(() => {
        fetchOrganigramme()
    }, [])



    const fetchOrganigramme = async () => {
        try {
            const request = await api(`api/rh/hierarchical-chart`, 'GET', { user_id: user?.user_id }, accessToken)
            const response = await request.json()
            console.log('Fetch hierarchical chart response:', response)
            setIsLoading(false)
            if (request?.ok && request?.status === 200) {
                setHierarchicalSubordinates(response?.hierarchical_subordinates)
                setMates(response?.mates)
                setUpperHierarchy(response?.upper_hierarchy)
            }
        } catch (error) {
            fetchOrganigramme()
            console.error(error);
        }
    }


    const renderItem = ({ user }) => {
        return (
            <Link className={styles.itemContainer}>
                <img
                    src={user?.profile?.prof_picture
                        ? user?.profile?.prof_picture
                        : require('assets/images/background_opinion.jpg')
                    }
                    alt=''
                    className={styles.image}
                />

                <p numberOfLines={2} className={styles.name}>{user?.user_name} {user?.user_surname}</p>
                <p numberOfLines={1} className={styles.username}>@{user?.user_username}</p>
            </Link>
        )
    }

    return (
        <div className={styles.page}>
            <Header
                title={
                    ` ${user?.user_name || ''} ${user?.user_surname || ''} - ${i18n.language == 'fr' ? 'Organigramme' : 'Organizational chart'}`
                }
                goBack={goBack}
            />
            <div className={styles.container}>


                {upperHierarchy &&
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className={styles.userContainer}>
                                <div style={{ width: '30%' }} >
                                    <div style={{
                                        width: '80px',
                                        width: '120px',
                                        borderRadius: '5px',
                                        padding: '5px',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        border: '1px solid #808080'
                                    }}>
                                        <div>
                                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                                <img
                                                    src={upperHierarchy?.profile?.prof_picture
                                                        ? upperHierarchy?.profile?.prof_picture
                                                        : require('assets/images/background_opinion.jpg')
                                                    }
                                                    style={{
                                                        width: '50px',
                                                        height: '50px',
                                                        borderRadius: '50px',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                    alt=''
                                                />
                                            </div>
                                            <p style={{ margin: '0px', width: '70px', textAlign: 'center', fontSize: '14px', fontWeight: '600' }}>
                                                {upperHierarchy?.user_name || ''} {upperHierarchy?.user_surname || ''}
                                            </p>
                                            <p style={{ margin: '0px', width: '70px', fontSize: '12px', color: '#808080', textAlign: 'center' }}>
                                                @{upperHierarchy?.user_username || ''}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginLeft: 'auto', marginRight: 'auto', height: '50px', width: '2px', backgroundColor: '#808080' }} />
                    </div>
                }
                <div style={{
                    width: '80px',
                    width: '120px',
                    borderRadius: '5px',
                    padding: '5px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    border: '1px solid ' + color.primary
                }}>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'center', }}>
                            <img
                                src={user?.profile?.prof_picture
                                    ? user?.profile?.prof_picture
                                    : require('assets/images/background_opinion.jpg')
                                }
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    borderRadius: '50px',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                                alt=''
                            />
                        </div>
                        <p style={{ margin: '0px', width: '70px', textAlign: 'center', fontSize: '14px', fontWeight: '600' }}>{user?.user_name || ''} {user?.user_surname || ''}</p>
                        <p style={{ margin: '0px', width: '70px', fontSize: '12px', color: '#808080', textAlign: 'center' }}>@{user?.user_username || ''}</p>
                    </div>
                </div>

                {hierarchicalSubordinates?.length !== 0 &&
                    <>
                        {hierarchicalSubordinates?.length > 1 &&
                            <>
                                <div style={{ marginLeft: 'auto', marginRight: 'auto', height: '50px', width: '2px', backgroundColor: '#808080' }} />
                                <div style={{ marginLeft: 'auto', marginRight: 'auto', height: '2px', width: '71%', backgroundColor: '#808080' }} />
                            </>
                        }

                        <div className={hierarchicalSubordinates?.length > 1
                            ? styles.subordinateContainer
                            : {
                                display: 'flex', justifyContent: 'center'
                            }}>
                            {hierarchicalSubordinates?.map((item, index) => {
                                return (
                                    <div>
                                        <div style={{ marginLeft: 'auto', marginRight: 'auto', height: '50px', width: '2px', backgroundColor: '#808080' }} />
                                        <div style={{
                                            width: '80px',
                                            width: '120px',
                                            borderRadius: '5px',
                                            padding: '5px',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            border: '1px solid #808080'
                                        }}>
                                            <div>
                                                <div style={{ display: 'flex', justifyContent: 'center', }}>
                                                    <img
                                                        src={item?.profile?.prof_picture
                                                            ? item?.profile?.prof_picture
                                                            : require('assets/images/background_opinion.jpg')
                                                        }
                                                        style={{
                                                            width: '50px',
                                                            height: '50px',
                                                            borderRadius: '50px',
                                                            marginLeft: 'auto',
                                                            marginRight: 'auto'
                                                        }}
                                                        alt=''
                                                    />
                                                </div>
                                                <p style={{ margin: '0px', width: '70px', textAlign: 'center', fontSize: '14px', fontWeight: '600' }}>{item?.user_name || ''} {item?.user_surname || ''}</p>
                                                <p style={{ margin: '0px', width: '70px', fontSize: '12px', color: '#808080', textAlign: 'center' }}>@{item?.user_username || ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Organizational