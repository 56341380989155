import React, { useState } from 'react'
import './Sidebar.style.css'
import { GoSignOut } from "react-icons/go";
import { FaUserCircle, FaUsers } from 'react-icons/fa'
import { MdOutlinePublic, MdAddBox, MdGroups, MdMoreHoriz, MdAdd } from 'react-icons/md'
import { AiFillMessage } from 'react-icons/ai'
import { Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import useReducerAuthAction from '../../hooks/ReducerHooksAction/useReducerAuthAction';
import { useAuthReducer, useOneToOneReducer } from '../../hooks/ReducerHooks/ReducerHooks';
import Profile from '../ProfileContent/ProfileContent';
import ModelAlert from '../ModalAlert/ModelAlert';
import Badges from '../badges/Badges';
import ModalCreateOpinion from '../ModalCreateOpinion/ModalCreateOpinion';
import { useTranslation } from 'react-i18next';
import { IoSearchSharp } from 'react-icons/io5';
import { Badge } from '@mui/material';


const Sidebar = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { innerWidth: width, innerHeight: height } = window;

  const { data: AuthData } = useAuthReducer()
  const language = localStorage.getItem('language')
  const [showMenu, setShowMenu] = useState(false)
  const { LOGOUT } = useReducerAuthAction()
  const { data: oneToOneStore } = useOneToOneReducer()
  const [showProfile, setShowProfile] = useState(false)
  const [showAddOpinion, setShowAddOpinion] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  // LOGOUT
  const logout = () => {
    LOGOUT()
  }

  return (
    <div className='sidebar'>

      <div className='logo-box'>
        <img src={require('assets/app/icon_header.png')}
          className='logo'
          alt=''
        />
      </div>

      {AuthData?.user?.user_username &&
        <div className='profile-box'>

          <Link to={`/profiles/user/${AuthData?.user?.user_username}`} style={{ textDecoration: 'none' }} className='user-box'>

            <button>
              <img
                src={
                  AuthData?.user?.profile?.prof_picture ?
                    AuthData?.user?.profile?.prof_picture :
                    require('assets/images/background_opinion.jpg')
                }
                alt=""
                style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '60px',
                  objectFit: 'cover'
                }}
              />
            </button>

            <div className='name-box ms-2'>
              <p className='name'>
                {AuthData?.user?.user_name} {AuthData?.user?.user_surname}
              </p>
              <p className='username'>
                @{AuthData?.user?.user_username}
              </p>
              <Badges user={AuthData?.user} />
            </div>

          </Link>

          {/* <div style={{ width: '20px' }}>
          <MdMoreHoriz onClick={() => setShowMenu(!showMenu)} size={24} color="#808080" style={{ cursor: 'pointer' }} />
        </div> */}

          {showMenu && (
            <div style={{ cursor: 'pointer' }} className='menu shadow'>
              <div
                onClick={() => {
                  setShowMenu(false)
                  setShowProfile(true)
                }}
                className='mb-2'>
                <FaUserCircle size={18} color='gray' />
                <Link to={`/profiles/user/${AuthData?.user?.user_username}`} style={{ textDecoration: 'none', color: '#222', marginLeft: '18px' }} >
                  {t('profile')}
                </Link>
              </div>
              <div onClick={logout} style={{ cursor: 'pointer' }}>
                <GoSignOut style={{ marginLeft: '1px' }} size={18} color='gray' />
                <button>{t('logout')}</button>
              </div>
            </div>
          )}

        </div>
      }

      <div onClick={() => navigate('home', { options: { replace: false } })} className='box'>
        <FaUsers className='icon' />
        <span className='title'>{t('contacts')}</span>
      </div>

      <div onClick={() => navigate('public')} className='box'>
        <MdOutlinePublic className='icon' />
        <span className='title'>{t('public_posts')}</span>
      </div>

      <div onClick={() => navigate('one-to-one')} className='box'>
        <Badge badgeContent={oneToOneStore?.message_number || 0} color="error" style={{ marginRight: '15px' }}>
          <AiFillMessage size={30} style={{ width: '30px', height: '30px', color: '#616060' }} />
        </Badge>
        <span className='title'>{t('inbox')}</span>
      </div>

      <div
        onClick={() => navigate('agora', { options: { replace: false } })}
        className='box'>
        <MdGroups className='icon' />
        <span className='title'>{t('groups')}</span>
      </div>

      <div
        onClick={() => navigate('search', { options: { replace: false } })}
        className='box'>
        <IoSearchSharp className='icon' />
        <span className='title'>{t('search')}</span>
      </div>

      <div onClick={() => setShowAddOpinion(true)} className='box-opinion'>
        <MdAdd size={30} className='icon' />
        <span className='title'>{t('publish')}</span>
      </div>

      <Profile
        user_id={AuthData?.user?.user_id}
        item={AuthData?.user}
        show={showProfile}
        setShow={setShowProfile}
        sourceFrom={'Home'}
      />

      <ModalCreateOpinion
        user={AuthData?.user}
        show={showAddOpinion}
        setShow={setShowAddOpinion}
      />

      <ModelAlert
        message={language === 'fr' ? 'Bientôt disponible.' : 'coming soon.'}
        show={showAlert}
        setShow={setShowAlert}
      />

    </div>
  )
}

export default Sidebar