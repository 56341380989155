import axios from 'axios'

export const fetchTranslateText = async (text, to) => {
    try {
        if (text && to) {
            const API_KEY = process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY
            const data = {
                q: text,
                target: to,
            }
            const response = await axios.post(
                `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`,
                data
            )
            // console.log('Translation text response:', response);
            // console.log('Translation text response:', response.data.data.translations[0].translatedText);
            return response.data.data.translations[0].translatedText
        } else {
            console.error('Translation failed, target text or language is empty');
        }
    } catch (error) {
        console.error('Translation failed', error);
    }
}
